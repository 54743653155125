import React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyles } from "./styles/GlobalStyles";
import { LandingPage } from "./component/views/LandingPage";
import { AboutPage } from "./component/views/AboutPage";
import { ServicesPage } from "./component/views/ServicesPage";
import { DiasporaPage } from "./component/views/DiasporaPage";

import { MoneyMarket } from "./component/rates/money_market.js";
import { DollarFunds } from "./component/rates/dollar_funds";
import { FixedIncome } from "./component/rates/fixed_income.js";
import { EquityFunds } from "./component/rates/equity_funds";
import { ShariahFunds } from "./component/rates/shariah_funds.js";
import { EthicalFunds } from "./component/rates/ethical_funds";
import { Sukuk } from "./component/rates/sukuk.js";

function App() {
  return (
    <>
      <GlobalStyles />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/services" component={ServicesPage} />
        <Route exact path="/diaspora" component={DiasporaPage} />
        <Route exact path="/money-market" component={MoneyMarket} />
        <Route exact path="/dollar-funds" component={DollarFunds} />
        <Route exact path="/fixed-income" component={FixedIncome} />
        <Route exact path="/equity-funds" component={EquityFunds} />
        <Route exact path="/shariah-funds" component={ShariahFunds} />
        <Route exact path="/sukuk" component={Sukuk} />
        <Route exact path="/ethical-funds" component={EthicalFunds} />
      </Switch>
      <ToastContainer />
    </>
  );
}

export default App;

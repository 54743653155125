import styled from "styled-components";
import image from "../assets/logo.svg";

const LogoWhite = styled.img.attrs({
  alt: "Wallet - Advisor ",
  src: image,
})`
  width: 12rem;
  height: auto;
`;

export default LogoWhite;
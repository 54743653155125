import React from "react";
import styled from "styled-components";
import { H2, Paragraph } from "./Text";
import { media, Solid, type } from "../variables/index";
import { Button } from "../atoms/button";
import mac from "../assets/mac.png";
import assessment from "../assets/assessment1.png";
import local from "../assets/local.png";
import store from "../assets/store.png";

const FifthBox = () => {
  return (
    <Container>
      <Text>
        <NH2>
          Our <br></br>features
        </NH2>
        <Card>
          <Icon src={local} alt=""></Icon>
          <P>Real-time information on price and performance </P>
        </Card>
        <Card>
          <Icon src={assessment} alt=""></Icon>
          <P>Easy access to the markets </P>
        </Card>
        <Card>
          <Icon src={store} alt=""></Icon>
          <P>Simplified investment process with no fancy language</P>
        </Card>
        <A href="https://invest.walletadvisor.ng/register">
          <Button size="blank-low">CREATE A FREE ACCOUNT</Button>
        </A>
      </Text>
      <Image>
        <IMG src={mac} alt="wallet advisor"></IMG>
      </Image>
    </Container>
  );
};

export default FifthBox;

const Container = styled.div`
  display: flex;
  height: 85vh;
  background: #ffffff;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 60vh;
  }

  @media ${media.tablet} {
    height: 55vh;
  }

  @media ${media.iphone} {
    flex-direction: column;
    height: auto;
  }
  @media ${media.mobile} {
  }
`;
const Image = styled.div`
  margin-left: 3rem;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    margin-left: 5rem;
  }
  @media ${media.iphone} {
    margin-top: 5rem;
  }
`;
const Card = styled.div`
  display: flex;

  @media ${media.tablet} {
    width: 70%;
  }
  @media ${media.iphone} {
    width: 90%;
  }
`;

const IMG = styled.img`
  width: 50rem;
  height: auto;

  @media ${media.desktopx} {
    width: 35rem;
  }

  @media ${media.tablet} {
    width: 30rem;
    margin-right: 5rem;
  }

  @media ${media.iphone} {
    margin-bottom: 5rem;
  }
`;

const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
`;

const A = styled.a`
  padding-right: 3rem;
  margin-top: 2rem;
  font-family: ${type.Abel};
  font-weight: 560;
  font-size: 2rem;
  text-decoration: none;
  color: ${Solid.HEADTEXT}
  &:hover {
    color: ${Solid.HEADTEXT}
  } 
  @media ${media.tablet} {
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 2rem;
  }
  @media ${media.iphone} {
    font-size: 1.4rem;
    padding-right: 1rem;
    margin-left: 4rem;
    margin-bottom: 5rem;
    
  }
  @media ${media.mobile} {
    font-size: 1.4rem;
    padding-right: 1rem;
    margin-left: 4rem;
    
  }
`;

const NH2 = styled(H2)`
font-size 3.8rem;
color: #2C3038;
margin-bottom: 3rem;

@media ${media.tablet} {
// font-size: 2.5rem;
  }

`;
const P = styled(Paragraph)`
  font-size: 1.8rem;
  text-align: justify;
  display: flex;
  align-items: center;
  color: "#555A64";

  @media ${media.tablet} {
    width: 90%;
    font-size: 1.7rem;
    text-align: center;
  }
  @media ${media.iphone} {
    width: 100%;
    font-size: 1.7rem;
    text-align: start;
  }
  @media ${media.mobile} {
    width: 100%;
    font-size: 1.8rem;
    text-align: start;
  }
`;

// export const Mac = styled.img.attrs({
//   alt: "Wallet - Advisor ",
//   src: mac,
// })`
//   max-width: 50rem;
//   height: auto;
// `;

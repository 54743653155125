import React from "react";
import styled from "styled-components";
import { H2, H3, Paragraph } from "../atoms/Text";
import { media, Solid } from "../variables/index";
import personIcon from "../assets/person_icon.png";
import addchart from "../assets/addchart.png";
import assessment from "../assets/assessment.png";

const ImageCard = () => {
  return (
    <Container>
      <Card>
        <Circle>
          <IMG src={personIcon} alt="wallet advisor"></IMG>
        </Circle>
        <NH2>1</NH2>
        <Text>
          <NH3>Open your WalletAdvisor account</NH3>
          <P>
            Just so we know a little bit about you. Sign up with your name, email address, and
            password to get started
          </P>
          <A href="https://invest.walletadvisor.ng/register">Open An Account</A>
        </Text>
      </Card>
      <Card>
        <Circle>
          <IMG src={addchart} alt="wallet advisor"></IMG>
        </Circle>
        <NH2>2</NH2>
        <Text>
          <NH3>
            Create <br></br>your Plan
          </NH3>
          <P>
            <span>
              Create a plan that helps you stay more focused towards achieving your investment
              objectives.
            </span>
          </P>
          <A2 href="https://invest.walletadvisor.ng/register">Learn More</A2>
        </Text>
      </Card>

      <Card>
        <Circle>
          <IMG src={assessment} alt="wallet advisor"></IMG>
        </Circle>
        <NH2>3</NH2>
        <Text>
          <NH3>
            View<br></br> Investments
          </NH3>
          <P>
            <span>If you're confident about investing, dive in and see what's on offer</span>
          </P>
          <A3 href="https://invest.walletadvisor.ng/login">View Investments</A3>
        </Text>
      </Card>
    </Container>
  );
};

export default ImageCard;

const Container = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-self: center;

  nth-of-type(2) {
    display: none;
    color: blue;
  }

  
  @media ${media.desktop} {
    width: 80%;
  }
  @media ${media.desktopx} {
    width: 85%;
  }


  @media ${media.tablet} {
    display: flex;
    width: 95vw;
    max-width: 100%;
    align-self: center;
    margin-left: 0rem;
    justify-content: space-around;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  width: 32rem;
  height: 40rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #0000000d;
  border: 2px solid #e4e5e6;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;

  
  &:hover {
    border-color: white;
  }

  @media ${media.tablet} {
    width: 32%;
    height: 33vh;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    height: auto;
    width: 85%;
    margin-bottom: 8rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin-bottom: 3rem;
  }
`;

const A = styled.a`
  color: ${Solid.CTA};
  padding-left: 5rem;
  margin-top: 3rem;
  text-decoration: none;

  @media ${media.tablet} {
    margin-top: 1rem;
  }
  @media ${media.iphone} {
    padding-left: 3.5rem;
    margin-top: 3.5rem;
  }
  @media ${media.mobile} {
    padding-left: 3.5rem;
    margin-top: 3.5rem;
  }
`;

const A2 = styled.a`
  color: ${Solid.CTA};
  padding-left: 5rem;
  margin-top: 5rem;
  text-decoration: none;

  @media ${media.tablet} {
    margin-top: 3rem;
  }

  @media ${media.iphone} {
    padding-left: 3.5rem;
  }
  @media ${media.mobile} {
    padding-left: 3.5rem;
  }
`;

const A3 = styled.a`
  color: ${Solid.CTA};
  padding-left: 5rem;
  margin-top: 6rem;
  text-decoration: none;

  @media ${media.tablet} {
    margin-top: 4rem;
  }
  @media ${media.iphone} {
    padding-left: 3.5rem;
  }
  @media ${media.mobile} {
    padding-left: 3.5rem;
  }
`;

const Circle = styled.div`
  background: #138d90;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5rem;
  margin-left: 4rem;
  box-shadow: 0px 20px 40px #138d904d;
  opacity: 1;

  @media ${media.tablet} {
    width: 8rem;
    height: 8rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 6rem;
    height: 6rem;
    margin-top: -3rem;
  }
`;

const IMG = styled.img`
  width: 5rem;
  height: auto;
  padding-left: 0.5rem;

  @media ${media.tablet} {
    width: 4.5rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin-left: 0rem;
    width: 3.5rem;
    margin-top: 0rem;
  }
`;

const NH2 = styled(H2)`
  color: #2c3038;
  opacity: 0.2;
  font-size: 6rem;
  align-self: flex-end;
  margin-left: 0;
  margin-right: 1.5rem;
  margin-top: -5rem;

  @media ${media.tablet} {
    margin-top: -2rem;
    font-size: 4rem;
  }
`;

const NH3 = styled(H3)`
  width: 90%;
  padding-left: 5rem;
  color: #2c3038;
  opacity: 1;

  @media ${media.tablet} {
    width: 80%;
    padding-left: 0;
    margin-left: 4rem;
  }

  @media ${media.iphone} {
    margin-left: 3.5rem;
  }
  @media ${media.mobile} {
    margin-left: 3.5rem;
  }
`;

const P = styled(Paragraph)`
width: 80%
  text-align: left;
  padding-left: 5rem;
  color: #555a64;
  opacity: 1;


  @media ${media.tablet} {
    padding-left: 0;
    margin-left: 4rem;

  }


  @media ${media.iphone} {
    margin-left: 3.5rem;
  }

  @media ${media.mobile} {
    margin-left: 3.5rem;
  }
  @media ${media.iphone} {
    margin-left: 3.5rem;
  }
`;

import styled from "styled-components";
import { media } from "../../variables/index";
import image from "../../assets/logo.png";

const Logo = styled.img.attrs({
  alt: "Wallet - Advisor ",
  src: image,
})`
  width: 25rem;

  @media ${media.desktop} {
    width: 20rem;
  }
  @media ${media.desktopx} {
    width: 18rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    display: none;
  }
`;

export default Logo;

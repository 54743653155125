import React from "react";
import styled from "styled-components";
import { media } from "../variables/index";
// import { Link } from "react-router-dom";
import { H3 } from "../atoms/Text";
import close from "../assets/close-24px.svg";

const NavItems = [
  {
    title: "Create account",
    url: "https://invest.walletadvisor.ng/register",
  },
  {
    title: "Login",
    url: "https://invest.walletadvisor.ng/login",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Nigerians in Diaspora",
    url: "/diaspora",
  },
  {
    title: "Services",
    url: "/services",
  },
];

const TopNav = ({ closeNav, height }) => {
  return (
    <SideNavMobile height={height}>
      <Content>
        <IMG onClick={closeNav} src={close} />
        <Header>Investments Training New Investors</Header>
        <ul style={{ marginTop: "3rem" }}>
          {NavItems.map(({ title, url }) => {
            return (
              <li key={title}>
                <A href={url}>{title}</A>
              </li>
            );
          })}
        </ul>
      </Content>
    </SideNavMobile>
  );
};

const SideNavMobile = styled.div`
display: none;

@media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
  display: block;
  background: #001334 0% 0% no-repeat padding-box;
  margin-left: 0rem;
  opacity: 1;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  align-items: center;

  h3 {
    margin: 3rem 0rem;
    font-size: 3.5rem;
    color: #fff;
  }

  button p {
    color: #fff;
  }

  ${({ height }) => {
    return `height: ${height}%`;
  }}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
`;

const IMG = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin: 2rem 0 2rem 2rem;
  align-self: flex-end;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin: 1rem 0rem 1rem 1rem;
    color: #ffffff;
  }
`;

const Header = styled(H3)`
  font-weight: 100;
  font-size: 4.5rem;

  @media screen and ${media.tablet} {
    font-size: 6.5rem;
  }
`;

const A = styled.a`
  display: flex;
  align-items: center;
  margin: 0 0 2.5rem 0;
  white-space: nowrap;
  text-decoration: none;
  color: white;
  
  opacity: 0.7;
  font-weight: 100;

  @media screen and ${media.tablet} {
    font-size: 4.5rem;
    margin-bottom: 3.5rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    font-size: 2.5rem;
  }
}`;

export default TopNav;

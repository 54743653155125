import React from "react";
import styled from "styled-components";
import { Paragraph, H2, H3 } from ".././Text";
import { media, Solid } from "../../variables/index";
import { ReactComponent as ThirdIcon } from "../../assets/about2.svg";

const SecondBox = () => {
  return (
    <Container>
      <DIV>
        <Image>
          <ThirdIcon />
        </Image>
        <Div>
          <NH3>OUR MISSION</NH3>
          <NH2>Our mission is to make investing simple and accessible</NH2>
          <P>
            because we know firsthand that the markets can sometimes feel
            overwhelming.
          </P>
          <P2>
            So we’ve created a simple onboarding process for newbies, and
            streamlined the process to remove some of the obstacles that might
            hinder your investing experience.
          </P2>
        </Div>
      </DIV>
    </Container>
  );
};

export default SecondBox;

const Container = styled.div`
  display: flex;
  height: 85vh;
  background: #f7f9fc;
  opacity: 1;
  justify-content: center;
  align-items: center;
  
  @media ${media.desktop} {
    height: 45vh
  }
  @media ${media.desktopx} {
    height: 85vh;
  }
  @media ${media.tablet} {
    height: 55vh;
  }

  @media ${media.iphone} {
    height: auto;
  }
`;
const Image = styled.div`
  margin-left: 12rem;
  margin-top: 4rem;

  @media ${media.desktopx} {
  margin-left: 2rem;
  }

  @media ${media.tablet} {
    margin-left: 2rem;
  }
  @media ${media.mobilex} {
    margin-left: 0;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: 6rem;

  @media ${media.tablet} {
    margin-left: 0;
  }
  @media ${media.mobile} {
    text-align: center;
  }
`;
const DIV = styled.div`
  width: 80%;
  display: flex;

  @media ${media.tablet} {
    width: 90%;
  }

  @media ${media.iphone} {
    display: flex;
    flex-direction: column;
}
@media ${media.mobile}, ${media.mobilex} {
 margin-bottom: 2rem;
  }
`;

const P = styled(Paragraph)`
  width: 70%;
  margin-left: 6rem;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  color: #001334;

  @media ${media.desktopx} {
    width: 95%;
    margin-left: 2rem;
  }
  @media ${media.tablet} {
    font-size: 1.8rem;
    width: 83%;
  }
  @media ${media.mobile} {
    margin-left: 2rem;
    }
`;

const P2 = styled(P)`
  width: 65%;

  @media ${media.mobile} {
    margin-left: 2rem;
    }
`;

const NH2 = styled(H2)`
  width: 80%;
  color: ${Solid.BLUE};
  margin-left: 6rem;
  font-size: 4rem;

  @media ${media.desktopx} {
    width: 100%;
    margin-left: 2rem;
    font-size: 3.5rem;
  }
  @media ${media.tablet} {
    width: 87%;
    font-size: 3.5rem;
  }
  @media ${media.mobile} {
    margin-left: 1.5rem;
    width: 92%;
    }
    @media ${media.mobilex} {
      margin-left: 0.5rem;

      }
`;
const NH3 = styled(H3)`
  width: 100%;
  color: #138d90;
  opacity: 1;
  letter-spacing: 2.5px;
  margin-left: 6rem;
  font-size: 1.8rem;


  @media ${media.mobile} {
  margin-left: 0;
  }
`;

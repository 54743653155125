import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../atoms/button";
import { media, Solid, type, Gradient } from "../variables/index";

const PublicNav = () => {
  return (
    <StyledPublicNav>
      <LINKED to="/">Home</LINKED>
      <LINK to="/about"> About </LINK>
      <LINK to="/services">Services</LINK>
      <LINK to="/diaspora">Nigerians in Diaspora</LINK>
      <A href="https://invest.walletadvisor.ng/login">
        <Button size="blank">Login</Button>
      </A>
      <A href="https://invest.walletadvisor.ng/register">
        <Button size="large">Create account</Button>
      </A>
    </StyledPublicNav>
  );
};

export default PublicNav;

const StyledPublicNav = styled.div`
  margin-right: 7rem;

  @media ${media.desktop} {
    width: auto;
  }

  @media ${media.tablet} {
    display: none;
  }
  @media ${media.mobile} {
    display: none;
  }
  @media ${media.iphone} {
    display: none;
  }
`;
const A = styled.a`
  padding-right: 3rem;
  font-family: ${type.Abel};
  font-style: normal;
  font-weight: 560;

  text-decoration: none;
  color: ${Solid.HEADTEXT} button {
    cursor: pointer;
  }
  &:hover {
    color: ${Solid.HEADTEXT};
  }

  @media ${media.desktop} {
    padding-right: 1rem;
  }
  @media ${media.tablet} {
    font-size: 1.6rem;
    font-weight: 400;
    padding-right: 2rem;
  }

  @media ${media.mobile} {
    font-size: 1.4rem;
    padding-right: 1rem;
  }
`;

const LINK = styled(Link)`
width: 5.4rem;
height: 3.1rem;
text-align: left;

  padding-right: 3rem;
  padding-top: 1rem;
  height: 3.8rem;
  font-family: ${type.Abel};
  font-style: normal;
  font-weight: 550;
  font-size: 1.8rem;
  text-decoration: none;
  color: #2C3038;
  ul {
    display: none;
      li {
        display: none;
      }
  }

  @media ${media.desktop} {
    padding-right: 1.5rem;
  }
  @media ${media.desktopx} {
    width: 8rem
    font-size: 1.5rem;
    height: 3.5rem
  }
  @media ${media.tablet} {
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 2rem;
  }

  @media ${media.mobile} {
    font-size: 1rem;
    font-weight: 400;
    padding-right: 1rem;
    
  }

  &:hover {
    cursor: pointer;
    color: #138D90;
    text-decoration: none;
  
  
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: block;
      }
    }
`;
const LINKED = styled(LINK)`
  color: ${Solid.BLUE};
`;

export const UL = styled.ul`
  position: relative;
  top: 6rem;
  right: 54rem;
  padding: 5px;
  list-style: none;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  border-radius: 6px;
  transition: 0.3s;

  @media ${media.tablet} {
    right: 35rem;
  }

  &:hover {
    cursor: pointer;
  }

  li {
    text-align: center;
    font-size: 1.2rem;
    a,
    span {
      font-weight: bold;
      font-size: 1.5rem;
      font-family: ${type.ABEL};
      padding: 1.5rem 2rem;
      text-decoration: none;
      color: ${Solid.BLUE};
      display: block;

      @media ${media.tablet} {
        padding: 1.2rem 1.5rem;
      }

      &:hover {
        background: ${Gradient.BLUE};
        color: ${Solid.WHITE};
        transition: all 0.3s;
      }
    }
  }
`;

export const Solid = {
  TANGERINE: '#F0810F',
  HEADTEXT : '#555A64',
  BLUE: '#001334',
  CTA: '#138D90',
  WHITE: '#FFFFFF',
  OFFWHITE: '#F7F9FC',
  TEXT: '#2C3038',
  OFFWHITE2 : '#0000000D',
  OFFWHITETHREE: '#E4E5E6',
  CTALIGHT: '#138D90',
  OFFFOUR: '#00000008',

};

export const Gradient = {
  BLUEISH: 'linear-gradient(to right, #1E77B4, #001334)',
  BLUEE: 'linear-gradient(to right,#3e6580, #001334)',
  BLUE: 'linear-gradient(to right, #1f4661, #001334)',
  GREEN: 'linear-gradient(to right, #77DD76, #187B32)',
  ORANGE: 'linear-gradient(to right, #E9B85F, #E18973)',
  GREY: 'linear-gradient(to right, #DADADA, #9D9D9D)',
};

export const type = {
  ABEL: "Abel"
};

// font-sizes
export const baseFontSize = "1.6rem";
export const smallFontSize = "1.4rem";
export const h1FontSize = "7.5rem";
export const h2FontSize = "5.5rem";
export const h3FontSize = "3.5rem";
export const h4FontSize = "2.5rem";

export const button_text = "16px";

import React from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Spinner({ visible }) {
  return (
    <Container>

      <Loader
        visible={visible}
        type="TailSpin"
        color="#FFFFFF"
        height={30}
        width={30}
        timeout={10000}
      />
    </Container>
  );
}

export default Spinner;

const Container = styled.div`

`
import React from "react";
import styled from "styled-components";
import { H2, Paragraph } from "../atoms/Text";
import { media } from "../variables/index";
import InvestmentCard from "../atoms/InvestmentCard";

const FourthBox = () => {
  return (
    <Container>
      <Top>
        <NH2>View and compare investments across various maturities and risk profiles</NH2>
        <P>
          All investments have some risk so it’s a good idea to understand what your{" "}
          <span>objectives</span> are and how much risk you’re willing to take before you dive in.
        </P>
      </Top>
      <Body>
        <InvestmentCard />
      </Body>
    </Container>
  );
};

export default FourthBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100vh;
  height: auto;
  background-color: #f7f9fc;
  opacity: 1;

  @media ${media.tablet} {
  }
  @media ${media.iphone} {
    height: auto;
  }
  @media ${media.mobile} {
    height: auto;
  }
`;

const Top = styled.div`
width: 68%
  display: flex;
  justify-content: space-between;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 10.8rem;

  @media ${media.desktop} {
    width: 80%;
    margin-left: 12rem;
  }
  
@media ${media.tablet} {
width: 75%;
margin-left: 10rem;
margin-right: 8rem;
margin-top: 8rem;
  }

  @media ${media.iphone} {
  flex-direction: column;
  width: 100%
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  margin-top: 4rem
  margin-bottom: 3rem;
  }

  @media ${media.mobile} {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-left: 1rem;
    }
    @media ${media.mobilex} {
      height: auto;
      }
`;

const Body = styled.div`
  display: flex;
  margin-bottom: 8rem;
`;

const NH2 = styled(H2)`
  width: 50%;
  font-size: 4rem;
  height: 20rem;
  text-align: left;
  color: #2c3038;
  opacity: 1;

  

  @media ${media.tablet} {
    width: 65%;
    font-size: 3.5rem;
    margin-right: 1rem;
  }

  @media ${media.iphone}, ${media.mobile} {
    width: 75%;
  }

  @media ${media.mobilex} {
    width: 80%;
    height: auto;
    }
`;

const P = styled(Paragraph)`
  width: 40%;
  font-size: 2.2rem;
  text-align: left;
  color: #555a64;
  opacity: 1;
  margin-top: 1.5rem;

  @media ${media.tablet} {
    width: 40%;
    font-size: 2rem;
  }
  @media ${media.iphone} {
    width: 80%;
  }

  @media ${media.mobile} {
    width: 70%;
    margin-right: 1rem;
  }
`;

// import React, { useState, useEffect } from "react";
import React, { useState } from "react";
import styled from "styled-components";

import { media } from "../../variables/index";
import { Link } from "react-router-dom";
import LogoWhite from "../../atoms/LogoWhite";
import image from "../../assets/money-bag(1).png";
import Container from "../../atoms/Container";
import { H2, Paragraph } from "../../atoms/Text.js";
import Header from "../../atoms/Header";
import dash from "../../assets/dash2.png";


import close from "../../assets/close-24px.svg";


export const ShariahFunds = () => {

  const [navWidth, setNavWidth] = useState(0);

  // const openNavHandler = () => {
  //   setNavWidth(100);
  // };

  const closeNav = () => {
    setNavWidth(0);
  };

  return (
    <Container>
      <FULLBODY>
        <div>
          <Header cta={"Go to homepage"} />;
        </div>
        <SideNav>

     
          <LINK to="/">
            <UnHoverItem>
              <IMGsmall src={dash}></IMGsmall>
              <P>Home</P>
            </UnHoverItem>
          </LINK>
          
        </SideNav>
        <SideNavMobile width={navWidth}>
          <div style={{ padding: "3rem" }}>
            <SideNavHeader>
            <A href="https://walletadvisor.ng">
                <LogoWhite />
              </A>
              <IMG onClick={closeNav} src={close} />
            </SideNavHeader>
          </div>
        </SideNavMobile>
        <BODY>
          <TopDivNav>
            <DivLink to="#"> Shari'ah-compliant investments</DivLink>
          </TopDivNav>
          <Top>
            <IMG src={image} alt="treasure" />
            <TopDiv>
              <H2>Shari'ah-compliant Investments</H2>
              <Paragraph>Shari'ah-compliant Investments rates available.</Paragraph>
              <span>To view our comprehensive rate list and service partners create an account with us today!</span>
            </TopDiv>
          </Top>
          <hr
              style={{
                opacity: "0.45",
                color: "#fff",
                backgroundColor: "#fff",
                height: 0.1,
                borderColor: "#fff",
              }} />
          <Content>
            <ItemHead>
              {/* <p>SERVICE PROVIDERS</p> */}
              <p>YEAR TO DATE RETURN</p>
              <p>MANAGEMENT FEES</p>
            </ItemHead>

            <RateItemDiv>
              {itemArray.map((item, i) => {
                return (
                  <ITEMDIV key={i}>
                    {/* <p>{item.isp}</p> */}
                    <p>{item.year + "%"}</p>
                    <p>{item.management + "%"}</p>
                  </ITEMDIV>
                );
              })}
            </RateItemDiv>
          </Content>
        </BODY>
      </FULLBODY>
    </Container>
  );
};

// const Linked = styled(Link)`
//   display: none;
//   @media ${media.mobile} {
//     display: block;
//   }
// `;
// const ActiveItemDiv = styled.div`
//   margin-top: 2rem;
// `;

const TopDivNav = styled.div`
  display: flex;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 1rem;

  @media ${media.iphone}, ${media.mobile} {
    display: none;
  }
`;
const DivLink = styled(Link)`
  text-decoration: none;
  color: #138d90;
`;
const Top = styled.div`
  display: flex;
  margin-top: 2%;
  margin-left: 3%;
  margin-bottom: 4%;
  align-items: center;

  @media ${media.iphone}, ${media.mobile} {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 27vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const IMG = styled.img`
  width: auto;
  height: auto;
  margin-right: 2rem;

  @media ${media.tablet} {
    width: 10%;
  }
  @media ${media.iphone}, ${media.mobile}, ${media.mobilex}{
    width: 15%;
    margin-left: 1rem;
  }
`;

const IMGsmall = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin: 2rem 0 2rem 2rem;

  @media ${media.tablet} {
    width: 2rem;
  }
`;

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  span {
    color: red;
  }

  @media ${media.tablet} {
    H2 {
      font-size: 2.2rem;
    }
    Paragraph {
      font-size: 1.6rem;
    }
  }
  @media ${media.iphone}, ${media.mobile}, ${media.mobilex} {
    width: 80%;
    margin-top: 0;
    H2 {
      font-size: 2rem;
    }
    Paragraph {
      font-size:1.5rem;
    }
    span {
      font-size: 1.2rem;
    }
  }
`;
const Content = styled.div`
  width: 95%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 3rem;

  @media ${media.tablet} {
    width: 90%;
  }

  @media ${media.mobile}, ${media.tab} {
    width: 85%;
  }
`;

const FULLBODY = styled.div`
  width: 100%;
  height: auto;
  background: #001334;
  display: flex;
  flex-direction: column;

  @media ${media.mobile}, ${media.iphone} {
    background: #f7f7fa 0% 0% no-repeat padding-box;
    opacity: 1;
  }
`;
const SideNav = styled.div`
  width: 18%;
  display: flex;
  flex-direction: column;
  margin-left: 2.7rem;
  position: absolute;
  top: 15rem;

  @media ${media.tablet} {
    margin-left: 1.6rem;
  }

  @media ${media.mobile}, ${media.iphone} {
    display: none;
  }
`;
const BODY = styled.div`
  width: 77%;
  height: auto;
  display: flex;
  flex-direction: column;

  margin-left: 20%;
  margin-bottom: 2rem;
  background-color: #f7f9fc;
  border-radius: 5px;

  @media ${media.mobile} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

const Item = styled.button`
  display: flex;
  width: auto;
  height: auto;
  background-color: #f7f9fc;
  margin-bottom: 2rem;
  border-radius: 5px 0 0 5px;
  opacity: 1;
  outline: 0;
  border: none;
  -moz-outline-style: none;
  box-shadow: none;
`;
const UnHoverItem = styled(Item)`
  background-color: #001334;
  width: 90%;
  height: auto;

  P {
    color: #7c8dac;
  }
  @media ${media.tablet} {
  }
`;

const LINK = styled(Link)`
  text-decoration: none;
  outline: 0;
  border: none;
  -moz-outline-style: none;
  box-shadow: none;
`;

const P = styled(Paragraph)`
  color: #001334;
  margin: 2.5rem 1.5rem;
`;


const ItemHead = styled.div`
width: 100%
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 3rem;
  margin-bottom: 2rem;

  p {
    font-size: 1.5rem;
    color: #001334;
  }

  @media ${media.tablet}{
    p {
      font-size: 1.2rem;
    }
  }
  @media ${media.iphone}, ${media.mobile} {
    p {
      font-size: 1rem;
    }
  }
`;
const ITEMDIV = styled.div`
height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #00000008;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 0.5rem;


  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  }


`;
const RateItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;





const SideNavHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SideNavMobile = styled.div`
  display: none;
  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    display: block;
    background: #001334 0% 0% no-repeat padding-box;
    margin-left: 0rem;
    opacity: 1;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    align-items: center;
    h3 {
      margin: 3rem 0rem;
      font-size: 3rem;
      color: #fff;
    }
    button p {
      color: #fff;
    }
    ${({ width }) => {
      return `width: ${width}%`;
    }}
`;


const A = styled.a`
  display: none;
  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    display: block;
  }
`;


const itemArray = [
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
  {
    isp: "Stanbic",
    year: 4.67,
    management: 6.99,
  },
 
];

import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import * as Yup from "yup";
// import { css } from 'glamor';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Solid, media } from "../variables/index";
import { H3, Paragraph } from "./Text";
import { Button } from "../atoms/button";
import { ReactComponent as Facebook } from "../assets/facebook.svg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Linkedin } from "../assets/linkedin.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";
import Spinner from "../atoms/Spinner";

const Footer = () => {
  const [ loading, setLoading ] = useState(false)

  const url = process.env.REACT_APP_API_URL;
  // const url = process.env.REACT_APP_TEST_URL;
  
  const showSuccess = message => {
      return toast.success(message, {
        position: toast.POSITION.TOP_RIGHT
      });;
    };

 const showError = message => {
     return toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
  
      });;
    };
  
  const saveEmail = (userEmail) => {

    setTimeout(function () {
      axios
        .post(`${url}/api/subscription`, {
          email: userEmail,
        })
        .then(
          (response) => {
            console.log(response.data.message)
            if(response) {
              showSuccess(response.data.message)
            }
            setLoading(false);
          },
          (error) => {
            showError(error);
          }
        );
    }, 2000);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      saveEmail(values.email);
    },
  });



  return (
    <Container>
      <Foot>
        <Div>
          <NH3>Company</NH3>
          <LINK to="/about">
            <P>About Us</P>
          </LINK>
          <LINK to="/diaspora">
            {" "}
            <P>Nigeria in Diaspora</P>
          </LINK>

          <P>Privacy</P>
          <P>Terms</P>
        </Div>
        <Div>
          <NH3>Product</NH3>
          <LINK to="/services">
            <P>Investments</P>
          </LINK>
          <P>Training</P>
          <A href="https://invest.walletadvisor.ng/login">
            <P>New Investors</P>
          </A>
        </Div>
        <Div>
          <NH3>Contact</NH3>
          {/* <P>
            John Doe Drive, The Rock Dr, <br></br>Lekki Phase 1, Lagos
          </P>
          <P> +2348123456789</P> */}
          <P>Walletadvisor.ng</P>
          <P>info@walletadvisor.ng</P>
        </Div>
        <DIV>
          <NH3>Newsletter</NH3>
          <LabelBox>
            <Form onSubmit={formik.handleSubmit}>
              <P>Subscribe to our newsletter</P>
              <Input
                value={formik.values.email}
                onChange={formik.handleChange}
                type="text"
                name="email"
                placeholder="Email Address"
              />
              <BUTTON type="submit" size="filled-small" >
              {loading === true ? <Spinner visible={loading} /> : "GO" }
              </BUTTON>
              <SmallP>We will never share your email address</SmallP>
            </Form>
          </LabelBox>
        </DIV>
      </Foot>
      <HR></HR>
      <Foot2>
        <P> © 2021 Wallet Advisor all rights reserved</P>
        <Icon>
          <FACEBOOK />

          <A href="https://twitter.com/wallet_advisor">
            <TWITTER />
          </A>
          <A href="https://www.linkedin.com/company/walletadvisor/">
            <LINKEDIN />
          </A>
          <A href="https://www.google.com/url?sa=t&source=web&rct=j&url=https://www.instagram.com/wallet.advisor/%3Fhl%3Den&ved=2ahUKEwijpob78q_uAhWSyYUKHUnJD70QFjAAegQIAxAB&usg=AOvVaw0Dr0d6ZWoCVEBunQdQwZTQ">
            <INSTAGRAM />
          </A>
        </Icon>
      </Foot2>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background: ${Solid.BLUE};

  @media ${media.desktop} {
    height: 60vh;
  }

  @media ${media.tablet} {
    height: 70vh;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    height: auto;
  }

  @media screen and (max-width: 320px) {
    padding-left: 4rem;
  }
`;

const LINK = styled(Link)`
  text-decoration: none;
`;
const A = styled.a`
  text-decoration: none;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 6rem;
  margin-left: 2rem;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin-bottom: 1rem;
  }
`;

const DIV = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${media.tablet} {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  @media ${media.mobilex} {
    margin-left: 0rem;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    justify-content: none;
    width: 70%;
  }
`;

const Form = styled.form`
  margin: auto;
  justify-content: space-between;
`;

const NH3 = styled(H3)`
  color: #ffffff;
`;

const HR = styled.hr`
width: 68%
opacity : 0.05;
margin-top:8rem;

@media ${media.tablet} {
  width: 90%
  }
`;

const BUTTON = styled(Button)`
  margin-top: 1rem;
  margin-left: 0.5rem;
  
`;

const Input = styled.input`
  width: 18.7rem;
  height: 4.2rem;
  text-align: center;
  border: none;
  background-color: #ffffff;
  opacity: 1;

  @media ${media.tablet} {
    width: 15.7rem;
  }
`;

const Foot = styled.div`
  display: flex;

  @media ${media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 1rem;
  }

  @media ${media.mobilex} {
    margin-left: 0rem;
  }
`;

const Foot2 = styled.div`
  display: flex;
  width: 68%;
  margin-top: 2rem;
  justify-content: space-between;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    display: flex;
    flex-direction: column;
    margin-bottom: 10rem;
    align-items: center;
    margin-top: 6rem;
  }

  @media ${media.mobilex} {
    margin-left: 0rem;
  }
`;

const LabelBox = styled.div`
  margin: auto;
  width: 34rem;
  height: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(112, 112, 112, 0.01);
  border-radius: 1rem;

  @media ${media.desktopx} {
    width: 30rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 30rem;
  }
  @media ${media.mobilex} {
    width: 25rem;
  }
`;

const P = styled(Paragraph)`
  color: #ffffff;
  opacity: 0.53;
  text-align: start;
`;

const SmallP = styled(Paragraph)`
  font-size: 1.3rem;
  text-align: left;
  color: ${Solid.WHITE};
  opacity: 0.53;
  margin-top: 1rem;
`;

const FACEBOOK = styled(Facebook)`
  margin-right: 2rem;
`;

const TWITTER = styled(Twitter)`
  margin-right: 2rem;
`;
const LINKEDIN = styled(Linkedin)`
  margin-right: 2rem;
`;
const INSTAGRAM = styled(Instagram)`
  margin-top: 0.3rem;
`;

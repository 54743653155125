import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import { H1, Paragraph } from "../Text";
import diaspora from "../../assets/diaspora.png";

const FirstBox = () => {
  return (
    <Container>
      <DIV>
        <Top>
          <NH1>
            The Nigerian economy presents some of the most profitable investment
            opportunities on the planet.
          </NH1>
          <Img src={diaspora} alt=""></Img>
        </Top>
        <P>
          Our population (expected to be the third largest globally by 2050),
          provides a ready and steady market for the demand of goods and
          services. Our size (number one in Africa by 2018 GDP figures), shows
          our resilience and permanence in spite of our over-reliance on oil.
          And our annual GDP growth rate between 2000 and 2018 averaged 5.84%,
          and is the largest of the MINT country averages – Mexico (2.20%),
          Indonesia (5.27%), and Turkey (5.12%).
          <P1>
            The potential investment returns to be made in our country are huge,
            but so also are the financing requirements. However funding from
            foreign investment sources require gains to be repatriated in
            foreign currency, and the resulting exchange rate instability may
            subsequently undermine whatever gains have been made. This affects
            both the domestic economy, and the foreign investor alike.{" "}
          </P1>
          <P1>
            To earn the levels of return expected in the Nigerian economy, the
            right type of investment is needed both from a country perspective
            and an investor perspective.
          </P1>
          <P1>And this is where we come in.</P1>
        </P>
      </DIV>
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  width: 100%;
  padding-top: 8rem;
  background: white;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
  }

  @media screen and ${media.tablet} {
    padding-top: 0rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    height: auto;
  }
`;

const Top = styled.div`
  width: 95%;
  display: flex;
  flex-direction: space-between;

  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;
const DIV = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 75%;

  @media ${media.desktopx} {
    width: 90%;
  }

  @media ${media.tablet} {
    width: 90%;
  }
  @media ${media.iphone} {
    width: 90%;
  }
`;

const Img = styled.img`
  width: 50%;
  height: auto;

  @media ${media.desktop}{
  width: 45%;
  }

  @media ${media.tablet} {
width: 90%
margin-top: 3rem;
   }
`;

const NH1 = styled(H1)`
  width: 50%;
  line-height: 6rem;
  font-weight: 400;
  font-size: 4.5rem;
  opacity: 1;
  margin-right: 4rem;

  @media ${media.desktopx} {
    width: 70%;
  }

  @media ${media.tablet} {
    width: 95%;
    margin-right: 0rem;
  }
  @media ${media.iphone} {
    width: 90%;
    font-size: 3rem;
    line-height: 4rem;
  }
`;

const P = styled(Paragraph)`
  width: 100%;
  margin-top: 8rem;
  font-size: 2rem;

  @media ${media.iphone} {
    text-align: center;
  }
`;

const P1 = styled(Paragraph)`
  width: 100%;
  margin-top: 4rem;
  font-size: 1.9rem;

  @media ${media.iphone} {
    text-align: center;
  }
`;

export const media = {
  // mobile: "(max-width: 375px)",
  fold: "(max-width: 320px)",
  xsMobile: "(max-width: 375px)",
  mobilex: "(max-width: 320px)",
  mobile: "(max-width: 600px)",
  tablet: "(max-width: 768px)",
  desktopx: "(max-width: 900px)",
  desktop: "(max-width: 1152px)",
  iphone: "(max-width: 414px)",
};

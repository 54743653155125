import React from "react";
import styled from "styled-components";
import { Solid, type, media } from "../variables/index";

export const Button = ({ children, size, ...props }) => {
  return (
    <StyledButton size={size} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  border: none;
  background: ${Solid.CTA};
  font-family: ${type.ABEL}
  color: white;
  font-size: 1.8rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  // border-radius: 0.5rem;

  @media ${media.tablet} {
    font-size: 1.5rem;
  }
  @media ${media.mobile} {
    font-size: 1rem;
  }

  ${({ size }) => {
    if (size === "small-blank") {
      return `
        width: 18.8rem;
        height: 8vh;
        background: #F7F9FC 0% 0% no-repeat padding-box;
        border: 2px solid ${Solid.CTA};
        opacity: 1;
        font-size: 1.5rem;
        cursor: pointer;

        @media ${media.desktop} {
          height: 5vh
        }
        
        @media ${media.desktopx} {
          height: 6vh
        }

        @media ${media.tablet} {
          width: 16rem;
          height: 4vh;
        }

        @media ${media.iphone} {
          width: 15rem;
          height: 6vh;
          font-size: 1rem;
        }
        @media ${media.mobile} {
          width: 15rem;
          height: 6vh;
          font-size: 1rem;
        }

      `;
    }
    if (size === "large") {
      return `
        width: 18.8rem;
        height: 5rem;
        background: #001334 0% 0% no-repeat padding-box;
        cursor: pointer;
        @media ${media.desktop} {
          width: 12rem;
          font-size: 1.6rem;
          height: 4rem;
        }
        @media ${media.desktopx} {
          width: 10rem
          font-size: 1.4rem;
          height: 3.5rem
        }
        @media ${media.tablet} {
          width: 26rem;
          height: 6rem
        }
        @media ${media.mobile} {
          width: 21rem;
          height: 4rem
        }
      `;
    }
    if (size === "blank") {
      return `
        width: 13.7rem;
        height: 5rem;
        font-size: 1.8rem;
        background: none;
        color: ${Solid.CTALIGHT};
        color: #138D90;
        border: 0.1rem solid ${Solid.CTALIGHT};
        cursor: pointer;

        @media ${media.desktop} {
          width: 10rem;
          fontsize: 1.6rem;
          height: 4rem;
        }
        @media ${media.desktopx} {
          width: 8rem
          font-size: 1.4rem;
          height: 3.5rem
        }
        @media ${media.tablet} {
          width: 26rem;
          height: 6rem
        }
        @media ${media.mobile} {
          width: 21rem;
          height: 4rem
        }
      `;
    }
    if(size === 'blank-low'){
      return `
      width: 18.7rem;
      height: 4.2rem;
      font-size: 1.5rem;
      background: #F7F9FC;
      color: #138D90;
      border: 0.1rem solid #138D90;
      cursor: pointer;

      @media ${media.tablet} {
        width: 20rem;
        height: 4rem
      }
      @media ${media.mobile} {
        width: 18rem;
        height: 4rem
      }
    `;
    }
    if(size === 'filled'){
      return `
      width: 18.7rem;
      height: 4.2rem;
      font-size: 1.5rem;
      background: #138D90;
      color: #FFFFFF;
      border: 2px solid #138D90;
      cursor: pointer;

      @media ${media.desktopx} {
        width: 16rem;
      }
      @media ${media.tablet} {
        width: 14rem;
        height: 4rem
      }
      @media ${media.mobile} {
        width: 14rem;
        height: 4rem
      }
    `;
    }
  if(size === 'filled-small'){
    return `
    width: 7.7rem;
    height: 4.2rem;
    font-size: 1.5rem;
    background: #138D90;
    color: #FFFFFF;
    border: 2px solid #138D90;
    cursor: pointer;

    @media ${media.tablet} {
      width: 6rem;
      height: 4rem
    }
    @media ${media.mobile} {
     
    }
  `;
  }
  }}
`;

import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import { H1, H2, H3, Paragraph } from "../Text";
import serviceBot from "../../assets/serviceBot.png";

const ThirdBox = () => {
  return (
    <Container>
      <DIV>
        <Top>
          <NH3> WE’VE HELPED MILLIONS OF PEOPLE</NH3>
          <NH1>And they actually enjoyed it.</NH1>
          <P>
            “Awesome service! Great communication, easy to navigate website,
            even better rate. Very happy”
          </P>
          <NH2>Ayomide</NH2>
        </Top>

        <div>
          <Img src={serviceBot} alt=""></Img>
        </div>
      </DIV>
    </Container>
  );
};

export default ThirdBox;

const Container = styled.div`
  width: 100%;
  height: 100vh;

  background: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-lign: center;

  @media ${media.desktop} {
    height: 50vh;
  }
  @media ${media.desktopx} {
    height: 60vh;
  }
  @media ${media.tablet} {
    height: 65vh;
  }
  @media ${media.iphone} {
    height: auto;
  }
  @media ${media.mobile} {
    height: auto;
  }
`;

const Top = styled.div`
  width: 100%;
`;
const DIV = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;
  align-items: center;

  @media ${media.tablet} {
    width: 80%;
  }
  @media ${media.iphone} {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  @media ${media.mobile} {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;

  @media ${media.iphone} {
    margin-bottom: 5rem;
  }
  @media ${media.mobile} {
    margin-bottom: 5rem;
  }
`;

const NH1 = styled(H1)`
  font-size: 4.5rem;
  opacity: 1;
  width: 70%;

  @media ${media.tablet} {
    width: 80%;
    font-size: 4rem;
  }
  @media ${media.iphone} {
    width: 100%;
    text-align: center;
  }
  @media ${media.mobile} {
    width: 100%;
    text-align: center;
  }
`;

const NH2 = styled(H2)`
  font-size: 1.5rem;
  color: #001334;
  opacity: 1;
`;

const P = styled(Paragraph)`
  width: 60%;

  @media ${media.tablet} {
    width: 90%;
  }
`;

const NH3 = styled(H3)`
  width: 100%;
  color: #138d90;
  opacity: 1;
  letter-spacing: 2px;
  font-size: 1.6rem;
`;

import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import { H1 } from "../Text";

const FirstBox = () => {
  return (
    <Container>
      <DIV>
        <NH1>
          WalletAdvisor.ng creates a systematic and structured process for Nigerians in the Diaspora
          seeking investment access to the Nigerian markets.
        </NH1>
      </DIV>
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  width: 100%;
  height: 60vh;
  background-color: #138d90;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-lign: center;

  @media ${media.desktop}{
    height: 40vh;
  }
  @media ${media.desktopx}{
    height: 50vh;
  }

  @media ${media.tablet} {
    height: 50vh;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const DIV = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70%;

  @media ${media.desktopx}{
    width: 90%;
  }

  @media ${media.tablet} {
    width: 80%;
  }
`;

const NH1 = styled(H1)`
  width: 70%;
  font-weight: 400;
  color: white;
  font-size: 4.2rem;
  opacity: 1;

  @media ${media.tablet} {
    font-size: 4rem;
  }

  @media ${media.iphone} {
    font-size: 3rem;
    width: 85%;
  }
`;

import React from "react";
import styled from "styled-components";
import { Paragraph, H2 } from ".././Text";
import { media, Solid } from "../../variables/index";
import secondIcon from "../../assets/about1.png";


const SecondBox = () => {
  return (
    <Container>
      <DIV>
        <Div>
          <NH2>WalletAdvisor.ng is a financial service provider</NH2>
          <P>
            which has been setup to smoothen out the investment process and
            provide easy access to the markets.
          </P>
          <P>
            We aggregate price and performance data across a wide variety of
            investments and present these in a simple, easy-to-use, and
            standardized format
          </P>
        </Div>
        <Image>
          <IMG src= {secondIcon} alt= ""></IMG>
        </Image>
      </DIV>
    </Container>
  );
};

export default SecondBox;

const Container = styled.div`
  display: flex;
  height: 80vh;
  background: #FFFFFF;
  opacity: 1;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 40vh;
  }
  @media ${media.desktopx} {
    height: 60vh
  }
  

  @media ${media.tablet} {
   height: 45vh;
  }

  @media ${media.iphone} {
  height: auto;

  }
`;
const IMG = styled.img`
width: 100%;


`
const Image = styled.div`
height: 45rem;
margin-left: 12rem;
margin-right: 4rem;



  @media ${media.tablet} {
    margin-right: 2rem;
    height: auto;
    margin-top: 6rem;
  }
  @media ${media.iphone} {
    height: auto;
    margin-left: 0rem;
margin-right: 0rem;
margin-bottom: 5rem;

  }
`;

const Div = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;


  @media ${media.tablet} {
    width: 80%;
  }


`;
const DIV = styled.div`
width: 80%;
display: flex;
margin-top: 13rem;

@media ${media.desktopx} {
  width: 95%;
}
@media ${media.tablet} {
  width: 90%;
  margin-top: 0;
}
@media ${media.iphone} {
    display: flex;
    flex-direction: column-reverse;
}

`;

const P = styled(Paragraph)`
  width: 100%;
  margin-left: 6rem;

  font-size: 1.9rem;

  display: flex;
  align-items: center;
  color: #555a64;

  @media ${media.tablet} {
    width: 100%;
    font-size: 1.8rem;
    margin-left: 4rem;
  }
  @media ${media.iphone} {
  text-align: center;
  margin-bottom: 2rem;
  }

  
`;

const NH2 = styled(H2)`
  width: 100%;
  color: ${Solid.BLUE};
  margin-left: 6rem;
  font-size: 4rem;
  // text-align: justify;

  @media ${media.tablet} {
    font-size: 3.5rem;
    margin-left: 4rem;
  }

  @media ${media.iphone} {
    margin-bottom: 4rem;
}
`;

import styled from "styled-components";
import { media } from "../variables/media";

const Container = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background-color: #f7f9fc;

  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    width: 100vw;
  }
`;

export default Container;

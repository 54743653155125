import React from "react";
import styled from "styled-components";
import { Paragraph, H2, H3 } from ".././Text";
import { media, Solid } from "../../variables/index";
import image from "../../assets/about3.png";

const SecondBox = () => {
  return (
    <Container>
      <DIV>
        <Div>
          <NH3>OUR VISION</NH3>
          <NH2>
            Our vision is one in which an investing culture has been built into
            the general consciousness of our society.
          </NH2>
          <P>
            Through our platform and forums, we bridge the gap between service
            providers and investors, and we conduct periodic financial awareness
            and educational campaigns to develop the right investment attitudes
            within the retail investing demography.
          </P>
        </Div>
        <Image>
          <IMG src={image} alt=""></IMG>
        </Image>
      </DIV>
    </Container>
  );
};

export default SecondBox;

const Container = styled.div`
  display: flex;
  height: 90vh;
  background:#FFFFFF;
  opacity: 1;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 50vh
  }
  @media ${media.desktopx} {
    height: 75vh;
  }

  @media ${media.tablet} {
    height 65vh;
  }

  @media ${media.iphone} {
    height: auto;
  

  }
`;
const IMG = styled.img`
  width: 100%;
`;                                                                                                                                                                                    
const Image = styled.div`
margin-right: 4rem;
margin-top: 9rem;

@media ${media.desktopx} {
    margin-right: 2rem;
}
  @media ${media.tablet} {
    margin-top: 15rem;
  }
  @media ${media.iphone} {
    margin-top: 5rem;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-right: 4rem;

   @media ${media.iphone}, ${media.mobile}{
    text-align: center;
   }

`;
const DIV = styled.div`
  width: 80%;
  display: flex;
  @media ${media.desktopx} {
    width: 95%;
  }
  @media ${media.tablet} {
    width: 950%;
}
@media ${media.iphone} {
  display: flex;
  flex-direction: column-reverse;
}
`;

const P = styled(Paragraph)`
  width: 70%;
  margin-left: 6rem;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  color: #001334;

  @media ${media.tablet} {
  }
  @media ${media.iphone} {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  @media ${media.mobile} {
  }
`;

const NH2 = styled(H2)`
  width: 75%;
  color: ${Solid.BLUE};
  margin-left: 6rem;
  font-size: 4rem;

  @media ${media.desktopx} {
    font-size: 3.8rem;
    width: 80%;
  }

  @media ${media.tablet} {
    font-size: 3.5rem;
}
@media ${media.iphone}, ${media.mobile}{
  margin-left: 2rem;
  width: 87%;
}
@media ${media.mobilex}{
  font-size: 3rem;
}
`;
const NH3 = styled(H3)`
  width: 100%;
  color: #138d90;
  opacity: 1;
  letter-spacing: 2.5px;
  margin-left: 6rem;
  font-size: 1.8rem;

  @media ${media.iphone}, ${media.mobile}{
    margin-left: 0rem;
    width: 100%;
   }
`;

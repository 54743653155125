import styled from "styled-components";
import { media } from "../../variables/index";
import image from "../../assets/logoMobile.svg";

const Logo = styled.img.attrs({
  alt: "Wallet - Advisor ",
  src: image,
})`
  display: none;

  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    display: inline;
    height: 3.5rem;
    margin: 1.5rem 2rem;
  }
`;

export default Logo;

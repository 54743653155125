import styled from 'styled-components';
import { type, Solid, media } from '../variables/index';


export const H1 = styled.h1`
  font-family: ${type.ABEL};
  color: ${Solid.BLUE};
  font-size: 56px;
  font-weight: 700;
  // white-space: nowrap;
  margin: 0 0 10px 0;
  padding: 0;
  @media ${media.mobile}{
    font-weight: 500;
  }
`;

export const H2 = styled.h2`
  font-family: ${type.ABEL};
  color: ${Solid.BLUE};
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 10px 0;
  padding: 0;
`;

export const H3 = styled.h3`
  font-family: ${type.ABEL};
  color: ${Solid.BLUE};
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 10px 0;
  padding: 0;
`;

export const H4 = styled.h4`
  font-family: ${type.ABEL};
  color: ${Solid.BLUE};
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
  padding: 0;
`;

export const Paragraph = styled.p`
  font-family: ${type.ABEL};
  color: ${Solid.BLUE};
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;

  normal {
    font-weight: normal;
  }

  strong {
    font-weight: bold;
  }
`;

export const PlainParagraph = styled(Paragraph)`
  margin: 0;
`;
import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { Button } from "./button";
import TopImage from "../atoms/images/TopImg";
import { H1, Paragraph } from "../atoms/Text";
import { media, Solid } from "../variables/index";

const FirstBox = () => {
  return (
    <Container>
      <Div>
        <NH1>
          Invest in over ₦25 trillion worth of investments with as little as
          ₦1000
        </NH1>
        <BUTTON size="small-blank">
          <A href="https://invest.walletadvisor.ng/register">
            CREATE FREE ACCOUNT{" "}
          </A>
        </BUTTON>
        <P>
          <i>*for selected investments</i>
        </P>
      </Div>

      <TopImage />
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 7rem;
  margin-left: 12rem;

  @media ${media.tablet} {
    width: 100%;
    margin-left: 0rem;
    margin-top: 5rem;
    height: 45vh;
    width: 90vw;
    align-self: center;
  }

  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.mobilex} {
    margin-top: 0rem;
    margin-left: 2rem;
    flex-direction: column;
    margin-left: 1rem;
    height: auto;
    align-items: center;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: #138d90;
`;

const Div = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  @media ${media.iphone} {
    margin-top: 2rem;
    width: 92%;
    margin-bottom: 7rem;
    align-items: center;
  }
  @media ${media.mobile} {
    margin-top: 2rem;
    width: 80%;
    margin-bottom: 7rem;
    align-items: center;
  }
`;

const NH1 = styled(H1)`
  width: 80%;
  line-height: 128.4%;
  text-align: left;
  color: #2c3038;
  font-size: 5rem;

  @media ${media.desktop} {
    font-size: 4rem;
  }


  @media ${media.tablet} {
    width: 100%;
    font-size: 3.8rem;
  }
  @media ${media.iphone} {
    width: 95%;
    font-size: 3.9rem;
    text-align: center;
  }
  @media ${media.mobile} {
    width: 95%;
    font-size: 3.6rem;
    text-align: center;
  }
`;

const P = styled(Paragraph)`
  font-size: 1rem;
  padding-top: 2.5rem;
  margin-left: 0.5rem;
  i {
    font-style: italic;
  }

  @media ${media.tablet} {
    margin-left: 0;
  }
  @media ${media.mobile} {
    display: none;
  }
  @media ${media.iphone} {
    display: none;
  }
`;

const BUTTON = styled(Button)`
  color: ${Solid.CTA};
  margin-top: 2rem;

  @media ${media.tablet} {
  }
  @media ${media.iphone} {
    text-align: center;
  }
  @media ${media.mobile} {
  }
`;

import React from "react";
import styled from "styled-components";
import { H2, Paragraph } from "../atoms/Text";
import ImageCard from "../atoms/ImageCard";
import { media } from "../variables/index";

const ThirdBox = () => {
  return (
    <Container>
      <Div>
        <H2>Get started in 3 simple steps</H2>
        <P>or select one of the three options below</P>
      </Div>
      <ImageCard />
    </Container>
  );
};

export default ThirdBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 70rem;
  background-color: #ffffff;

  @media screen and ${media.tablet} {
    heigth: 40rem;
    align-self: center;
    width: 100%;
  }

  @media ${media.iphone} {
    height: auto;
  }

  @media ${media.mobile} {
    max-heigth: 80rem;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 10rem;

  @media ${media.mobile} {
    padding-bottom: 5rem;
  }

  @media ${media.mobile} {
    H2 {
      font-size: 2.5rem;
    }
    P {
      font-size: 1.6rem;
    }
  }
`;

const P = styled(Paragraph)`
  height: 2rem;
  font-size: 2rem;
  line-height: 177.4%;

  @media ${media.mobile} {
    height: 4rem;
  }
`;

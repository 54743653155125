import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { H2 } from "./Text";
import { media } from "../variables/index";
import { Button } from "../atoms/button";
import image from "../assets/Image2.png";
import group from "../assets/group-down.png";

const SixthBox = () => {
  return (
    <Container>
        <Image>
          <IMG src={image} alt=""></IMG>
        </Image>
        <Div>
          <NH2>Start investing today</NH2>
          <Button size="filled"><A href="https://invest.walletadvisor.ng/register">INVEST NOW</A></Button>
        </Div>
        <IMAGE>
          <Icon src={group} alt=""></Icon>
        </IMAGE>

    </Container>
  );
};

export default SixthBox;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 85vh;
  background: #f7f9fc;
  opacity: 1;

  @media ${media.desktop} {
    height: 45vh;
  }
  @media ${media.desktopx} {
    height: 55vh;
  }
  
  @media ${media.tablet} {
    height: 38vh;
  
  }
    
  @media ${media.iphone} {
    height: auto;
    flex-direction: column-reverse;
  
  }
    
  @media ${media.mobile} {
    height: auto;
  
  }
`;
const Div = styled.div`
@media ${media.desktopx} {
  width: 70%;

}


@media ${media.tablet} {
  width: 80%;
 }

 @media ${media.iphone} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
 }
`;
const A = styled.a`
text-decoration: none;
color: #FFFFFF;
`

const Image = styled.div`
  margin-top: 6.5rem;
  margin-left: 10rem;
  opacity: 1;

  @media ${media.tablet} {
 margin-left: 3rem;
 height: auto;
 margin-top: 13.5rem;
 margin-right: 2rem;
  }
  @media ${media.iphone} {
    margin-top: 8rem;
    margin-bottom: -1rem;
  
  }
`;
const IMG = styled.img`
  width:100%;
  height: auto;

  @media ${media.tablet} {
    
   }
`;
const Icon = styled.img`
  width: 95%;
  height: auto;

  @media ${media.tablet} {
    width: 90%;
   }
`;

const IMAGE = styled.div`
  margin-top: 16rem;
  text-align: right;
  
  @media ${media.tablet} {
    width: 90%;
    margin-top: 14rem;
   }
   @media ${media.iphone} {
    display: none;
   }
   @media ${media.mobile} {
    display: none;
   }
  
`;

const NH2 = styled(H2)`
  margin-top: 12rem;
  margin-bottom: 4rem;
  font-size: 4.2rem;
  opacity: 1
  @media ${media.desktopx} {

    font-size: 3.8rem;
    margin-top: 8rem;
    margin-bottom: 2rem;
  }


  @media ${media.tablet} {
   margin-top: 8rem;
    font-size: 3.5rem;
   width: 90%;
   }
  @media ${media.iphone} {
    margin-top: 1rem;
    width: 70%;
  }

  @media ${media.mobile} {
    margin-top: 1rem;
  }
`;

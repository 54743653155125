import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "./images/Logo";
import LogoMobile from "./images/LogoMobile";
import PublicNav from "../atoms/PublicNav";
import icon from "../assets/menu-24px.svg";
import { media } from "../variables/index";

const Header = ({ openNav }) => (
  <HeaderContainer>
    <Linked to="/">
      <Logo />
      <LogoMobile />
    </Linked>
    <Hamburger onClick={openNav} src={icon} />
    <PublicNav />
  </HeaderContainer>
);

export default Header;

const HeaderContainer = styled.div`
  height: 14vh;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f9fc;

  @media ${media.tablet} {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000d;
    opacity: 1;
  }

  @media ${media.mobile} {
    height: 8rem;
  }
`;

const Hamburger = styled.img`
  display: none;

  @media screen and ${media.tablet} {
    display: inline;
    margin-right: 2rem;
  }
`;

const Linked = styled(Link)`
  margin-left: 7rem;

  @media ${media.tablet} {
    margin-left: 3rem;
  }

  @media ${media.mobile} {
    margin-left: 0rem;
  }
`;

import styled from "styled-components";
import { media } from "../../variables/index";
import image from "../../assets/topLanding.png";

const TopImage = styled.img.attrs({
  alt: "Wallet advisor investment image ",
  src: image
})`
  width: 45rem;
  height: auto;
  margin-right: 10rem;

  @media ${media.desktopx} {
    width: 35rem;
  }
  
  @media ${media.tablet} {
    height: 100%;
    margin-right: 0;
    
  }
  @media ${media.iphone} {
    width: 38rem;
    height: auto;
    margin-right: 5rem;
  }
  @media ${media.mobile},${media.xsMobile} {
    height: auto;
    width: 90%;
  }
  @media ${media.mobilex}{
    height: auto;
    width: 90%;
  }
`;

export default TopImage;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Paragraph } from "../atoms/Text";
import { media } from "../variables/index";
import treasureChest from "../assets/treasure-chest.png";
import creditCard1 from "../assets/credit-card(1).png";
import profits from "../assets/profits.png";
import creditCard2 from "../assets/credit-card(2).png";
import money6 from "../assets/money(6).png";
import money7 from "../assets/money(7).png";
import money8 from "../assets/money(8).png";
import moneyBag1 from "../assets/money-bag(1).png";
import moneyBag2 from "../assets/money-bag(2).png";
import salary from "../assets/salary.png";
import savings from "../assets/savings.png";
import moneyBag3 from "../assets/money-bag(3).png";

const InvestmentCard = () => {
  return (
    <Container>
      {investmentArray.map((options, i) => {
        return (
          <LINK key={i} to={options.link}>
            <Card key={i}>
              <P>{options.investmentName}</P>
              <IMG src={options.image} alt="wallet advisor"></IMG>
            </Card>
          </LINK>
        );
      })}
    </Container>
  );
};

export default InvestmentCard;

const Container = styled.div`
  display: grid;
  width: 70%;

  margin: auto;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;

  
  @media ${media.desktop} {
    width: 80%;
  }
  @media ${media.desktopx} {
    width: 75%;
  }


  @media ${media.tablet} {
    width: 80%;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr
    margin-bottom: 2rem;
  }
`;
const LINK = styled(Link)`
  text-decoration: none;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 100%;
  }
`;

const Card = styled.div`
  width: 20rem;
  height: 15rem;
  display: flex;
  flex-direction column;
  align-items: center;
  padding-top: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 20px 40px #00000008;
  border: 2px solid #e4e5e6;
  opacity: 1;

  
  &:hover {
    border: 10px solid white;
  }

  @media ${media.desktopx} {
    width: 17rem;
  }

  @media ${media.tablet} {
    width: 18rem;
    height: 14rem;
    }
    
  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 95%;
  }
`;

const P = styled(Paragraph)`
  color: #2c3038;
  opacity: 1;

  @media screen and ${media.tablet} {
    text-align: center;
    font-size: 1.6rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const IMG = styled.img`
  width: 5rem;
  height: auto;
  padding-left: 0.5rem;

  @media ${media.tablet} {
    width: 4rem;
  }
`;

const investmentArray = [
  {
    investmentName: "Treasury Bills",
    image: treasureChest,
    link: "#",
  },
  {
    investmentName: "Fixed Deposits",
    image: creditCard1,
    link: "#",
  },
  {
    investmentName: "Money Market Funds",
    image: profits,
    link: "/money-market",
  },
  {
    investmentName: "Fixed Income/Bond Funds",
    image: creditCard2,
    link: "/fixed-income",
  },
  {
    investmentName: "Equity Funds",
    image: money6,
    link: "/equity-funds",
  },
  {
    investmentName: "Exchange Traded Funds",
    image: money7,
    link: "#",
  },
  {
    investmentName: "FGN Savings Bonds",
    image: money8,
    link: "#",
  },
  {
    investmentName: "Shari'ah Compliant Funds",
    image: moneyBag1,
    link: "/shariah-funds",
  },
  {
    investmentName: "Sukuk",
    image: moneyBag2,
    link: "/sukuk",
  },
  {
    investmentName: "Ethical Funds",
    image: salary,
    link: "/ethical-funds",
  },
  {
    investmentName: "Green Bonds",
    image: savings,
    link: "#",
  },
  {
    investmentName: "Dollar Based Investments",
    image: moneyBag3,
    link: "/dollar-funds",
  },
];

import React from "react";
import styled from "styled-components";
import AboutImage from "../images/AboutImg";
import { media } from "../../variables/index";

const FirstBox = () => {
  return (
    <Container>
      <AboutImage />
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  @media ${media.tablet} {
  }
  @media ${media.iphone} {
  }
  @media ${media.mobile} {
  }
`;

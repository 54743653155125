import React from "react";
import styled from "styled-components";
import { Paragraph, H2 } from "./Text";
import { media, Solid } from "../variables/index";
import { ReactComponent as SecondIcon } from "../assets/second.svg";

const SecondBox = () => {
  return (
    <Container>
      <Image>
        <SecondIcon />
      </Image>
      <Div>
        <NH2>Ever tried investing in the markets and can't seem to find your way?'</NH2>
        <P>We're here to calm the noise and help you make sense of it.</P>
      </Div>
    </Container>
  );
};

export default SecondBox;

const Container = styled.div`
  display: flex;
  height: 45rem;
  background: ${Solid.BLUE};
  justify-content: center;
  align-items: center;

  @media ${media.tablet} {
    height: 40rem;
  }

  @media ${media.iphone} {
    flex-direction: column;
    height: auto;
  }
  @media ${media.mobile} {
    flex-direction: column;
    height: auto;
  }
`;
const Image = styled.div`
  width: 100%;
  height: 45rem;
  margin-left: 20rem;
  margin-right: 4rem;

  @media ${media.desktop} {
    margin-left: 12rem;
  }
  
  @media ${media.desktopx} {
    margin-left: 5rem;
  }

  @media ${media.tablet} {
    height: 45rem;
    margin-left: 6rem;
    margin-right: 0;
  }
  @media ${media.iphone} {
    width: 40rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  @media ${media.iphone} {
    width: 40rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${media.iphone} {
    align-items: center;
    margin-bottom: 4rem;
  }
`;

const P = styled(Paragraph)`
  width: 47%;
  margin-left: 6rem;
  height: 10vh;
  font-size: 2rem;
  text-align: justify;
  display: flex;
  align-items: center;
  color: white;

  
  @media ${media.desktop} {
    margin-left: 5rem;
    width: 65%;
  }
  @media ${media.desktopx} {
    margin-left: 3rem;
  }

  @media ${media.tablet} {
    width: 70%;
    font-size: 1.8rem;
    margin-left: 2rem;
  }
  @media ${media.iphone} {
    font-size: 1.8rem;
    text-align: center;
    margin-left: 0;
  }
  @media ${media.mobile} {
  }
`;

const NH2 = styled(H2)`
  width: 65%;
  color: white;
  margin-left: 6rem;
  font-size: 4rem;

  @media ${media.desktop} {
    width: 75%;
    margin-left: 4rem;
  }
  
  @media ${media.desktopx} {
    margin-left: 3rem;
    font-size: 3.5rem;
    width: 80%;
  }

  @media ${media.tablet} {
    width: 80%;
    font-size: 3rem;
    margin-left: 2rem;
  }

  @media ${media.iphone} {
    font-size: 2.8rem;
    text-align: center;
    margin-left: 0;
  }
`;

import React from "react";
import styled from "styled-components";
import image from "../../assets/about.png";
import { H1 } from "../Text";
import { media } from "../../variables/index";

export default function AboutImage() {
  return (
    <StyledReg>
      <Content>
        <HH1>
          We believe that the citizenry of a nation holds the key to
          its economic potential.
        </HH1>
      </Content>
    </StyledReg>
  );
}

const StyledReg = styled.div`
  width: 100%;
  height: 45rem;
  display: flex;
  text-align: center;
  justify-content: center;
  background: url(${image}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;

  @media ${media.tablet} {
   
  }

  @media ${media.mobile} {
   
  }
  @media ${media.iphone} {
    height: 35vh;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  text-align: center;
  align-items: center;

  @media ${media.iphone} {
    width: 80%;

  }
  
`;

const HH1 = styled(H1)`
  font-size: 4.5rem;
  color: white;

  
  @media ${media.iphone} {
   font-size: 3rem;
   font-weight: normal
  }
`;

import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import { Paragraph } from "../Text";
// import diaspora from "../../assets/diaspora.png";

const FirstBox = () => {
  return (
    <Container>
      <DIV>
        <P>
          We’re simple, we’re transparent, and we’re easy to use. Our mission is
          to make investing simple and accessible for you, the individual.
        </P>
        <P1>
          We’re also independent, and we stay clear from any influences that
          affect our ability to provide you with unbiased information on
          investment opportunities in our markets.
        </P1>
        <P1>
          We aim to be the first point of contact on investments for Nigerians
          resident abroad; we aggregate and standardize investment information
          across various market segments to help investors make disciplined
          investment choices.
        </P1>
        <P1>
          Finally, we aim to harness remittance inflows (estimated at over
          $23.8bn as at 2019), towards more productive uses and by so doing
          mobilize funds for the growth of our economy.
        </P1>
      </DIV>
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  width: 100%;
  height: 80vh;
  background: none;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-lign: center;

  @media ${media.desktop} {
    height: 50vh;
  }
  @media ${media.desktopx} {
    height: 60vh;
  }
  @media ${media.tablet} {
    height: 60vh;
  }

  @media screen and ${media.xsMobile}, ${media.mobile}, ${media.tablet} {
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const DIV = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 75%;

  @media ${media.iphone} {
    text-align: center;
  }
`;

const P = styled(Paragraph)`
  width: 95%;
  font-size: 1.9rem;
`;

const P1 = styled(Paragraph)`
  width: 95%;
  margin-top: 2rem;
  font-size: 1.9rem;
`;

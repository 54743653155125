import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import InvestmentCard from "../InvestmentCard";
import { H1, Paragraph } from "../Text";
import { ReactComponent as Service } from "../../assets/service1.svg";
import { ReactComponent as Service2 } from "../../assets/service2.svg";

const FirstBox = () => {
  return (
    <Container>
      <DIV>
        <Ndiv>
          <NH1>View and compare investments across various maturities and risk profiles.</NH1>
          <P>
            All investments have some risk so it’s a good idea to understand what your objectives
            are and how much risk you’re willing to take before you dive in.
          </P>
        </Ndiv>
        <Image>
          <Service />
        </Image>
      </DIV>
      <Div>
        <IMAGE>
          <Service2 />
        </IMAGE>
        <Invest>
          <InvestmentCard />
        </Invest>
      </Div>
    </Container>
  );
};

export default FirstBox;

const Container = styled.div`
  width: 100%;
  background: white;

  @media ${media.tablet} {
    width: 100%;
    height: auto;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    height: auto;
  }
`;

const DIV = styled.div`
  display: flex;
`;

const Div = styled.div`
  display: flex;

  @media ${media.tablet} {
    width: 100%
    height: 80vh;
  }

  @media screen and ${media.xsMobile}, ${media.mobile}{
    height: auto;
    margin-bottom: 10rem;
  }
  
 
`;

const IMAGE = styled.div`
  padding-top: 25rem;
  padding-left: 7rem;
  z-index: 1;
  @media ${media.desktopx} {
    z-index: -1;
  }

  @media ${media.tablet} {
    z-index: 0;
    padding-top: 45rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    display: none;
  }
`;
const Ndiv = styled.div`
  margin: auto;
  width: 60%;
  text-align: center;
  padding-left: 10rem;

  @media ${media.tablet} {
    width: 60%;
    padding-left: 2rem;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 90%;
    margin-top: 1rem;
    padding-left: 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const NH1 = styled(H1)`
  font-size: 4rem;

  color: #2c3038;
  opacity: 1;

  @media screen and ${media.xsMobile}, ${media.mobile} {
    font-size: 3.6rem;
  }
`;

const P = styled(Paragraph)`
  color: #2c3038;
  opacity: 1;
  width: 80%;
  margin-left: 6rem;
  font-size: 1.8rem;

  @media ${media.tablet} {
    margin-left: 0;
    width: 100%;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    width: 80%;
    margin-left: 0;
  }
`;

const Image = styled.div`
  margin-right: 8rem;
  padding-top: 7rem;

  @media ${media.tablet} {
    margin-right: 2rem;
    z-index: 1;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    display: none;
  }
`;

const Invest = styled.div`
  width: 100%;
  margin-top: -5rem;
  margin-left: -22rem;
  margin-bottom: 10rem;

  @media ${media.desktopx} {
    margin-left: -26rem;
  }

  @media ${media.tablet} {
    margin-top: -5rem;
    z-index: 1;
  }

  @media screen and ${media.xsMobile}, ${media.mobile} {
    margin: 0;
  }
`;

import React, { useState } from "react";
import Container from "../../atoms/Container";
import Header from "../../atoms/Header";
import FirstBox from "../../atoms/FirstBox";
import SecondBox from "../../atoms/SecondBox";
import ThirdBox from "../../atoms/ThirdBox";
import FourthBox from "../../atoms/FourthBox";
import FifthBox from "../../atoms/FifthBox";
import SixthBox from "../../atoms/SixthBox";
import Footer from "../../atoms/Footer";
import TopNav from "../../atoms/TopNav";
import styled from "styled-components";

export const LandingPage = () => {
  const [height, setHeight] = useState(0);
  const openNavHandler = () => {
    setHeight(100);
  };

  const closeNav = () => {
    setHeight(0);
  };

  return (
    <Container>
      <TopNav height={height} closeNav={closeNav} />
      <PageContent height={height}>
        <Header openNav={openNavHandler} />
        <FirstBox />
        <SecondBox />
        <ThirdBox />
        <FourthBox />
        <FifthBox />
        <SixthBox />
        <Footer />
      </PageContent>
    </Container>
  );
};

const PageContent = styled.div`
  flex-direction: column;
  ${({ height }) => {
    if (height === 0) return `display: flex`;
    else return `display: none`;
  }}
`;

import React from "react";
import styled from "styled-components";
import image from "../../assets/about4.png";
import { H1 } from "../Text";
import { media } from "../../variables/index";
import { Button } from "../button";

export default function AboutImage() {
  return (
    <StyledReg>
      <Content>
        <HH1>
          Start investing today. <br></br>
          Create your free account.
        </HH1>
        <But size="filled"><A href="https://invest.walletadvisor.ng/register">INVEST NOW</A></But>
      </Content>
    </StyledReg>
  );
}

const StyledReg = styled.div`
  width: 100%;
  height: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: -0.1rem;
  background: url(${image}) no-repeat center center;
  background-size: cover;
  text-align: center;

  @media ${media.tablet} {
   
  }

  @media ${media.iphone} {
   height: 45vh;
  }
`;
const A = styled.a`
text-decoration: none;
// color: #FFFFFF;
`

const But = styled(Button)`
  background-color: #ffffff;
  color: #138d90;
  
  @media ${media.iphone} {
   border: none;
   margin-top: 5rem;
  }
`;

const Content = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media ${media.iphone} {
    width: 80%;
}
`;

const HH1 = styled(H1)`
  font-size: 4rem;
  color: white;
  opacity: 1;

  @media ${media.iphone} {
    font-size: 3rem;
    font-weight: normal;
}
`;

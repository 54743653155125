import React from "react";
import styled from "styled-components";
import { media } from "../../variables/index";
import { H1, H2, H3, Paragraph } from "../Text";
import mac from "../../assets/mac2.png";


const SecondBox = () => {
  return (
    <Container>
      <Top>
        <NH3> INVESTMENT SIMPLIFIED</NH3>
        <NH1>How it works</NH1>
      </Top>

      <DIV>
        <div>
          <Img src={mac} alt=""></Img>
        </div>
        <Div>
          <NH2>Open An Account</NH2>
          <P>
            Just so we know a little bit about you. Sign up with your name,
            email address, and password to get started.
          </P>

          <NH2>Create your Plan</NH2>
          <P>
            Creating a plan helps you stay more focused towards achieving your
            investment objectives.
          </P>

          <NH2>View Investments</NH2>
          <P>
            If you’re confident about investing, dive in and see what’s on
            offer.
          </P>
        </Div>
      </DIV>
    </Container>
  );
};

export default SecondBox;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: -15vh;
  background: #f7f9fc;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-lign: center;

  @media ${media.desktop}{
    height: 60vh;
  }
  @media ${media.desktopx}{
    height: 80vh;
  }

  @media ${media.tablet} {
    height: 78vh;
  }
  @media ${media.iphone} {
    height: auto;
  }
  @media ${media.mobile} {
    height: auto;
  }
`;

const Top = styled.div`
  margin-bottom: 9rem;

  @media ${media.tablet} {
  margin-bottom: 5rem;
  }

  @media ${media.iphone} {
    margin-top: 5rem;
  }
  @media ${media.mobile} {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
`;
const DIV = styled.div`
width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media ${media.tablet} {
    width: 90%;

    div {
      margin-right: 3rem;
      margin-left: 5rem;
    }
    
  }

  @media ${media.iphone} {
   flex-direction: column;
   margin-bottom: 5rem;
  }
  @media ${media.mobile} {
   flex-direction: column;
   margin-bottom: 5rem;
  }
  
`;

const Img = styled.img`
  width: 80%;
  height: auto;

  @media ${media.tablet} {
    width:100%;
    margin-top: 8rem;
    
  }
  @media ${media.iphone} {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  @media ${media.mobile} {
    margin-top: 1rem;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${media.iphone} {
    align-items: center;
  }
  @media ${media.mobile} {
    align-items: center;
  }
`;

const NH1 = styled(H1)`
font-size: 4.5rem;


@media ${media.iphone} {
  font-size: 3rem;
}
@media ${media.mobile} {
  font-size: 3rem;
}
`;

const NH2 = styled(H2)`
font-size: 3rem;
color: #2C3038;
opacity: 1;
`;

const P = styled(Paragraph)`
  width: 70%;

  @media ${media.tablet} {
    width: 90%;
    
  }
  @media ${media.iphone} {
    text-align: center;
    
  }
  @media ${media.mobile} {
    text-align: center;
    
  }
  
`;

const NH3 = styled(H3)`
  width: 100%;
  color: #138d90;
  opacity: 1;
  letter-spacing: 2px;
  font-size: 1.6rem;

`;
